export const performanceEvents = {
  onEngineStarting         : 'onEngineStarting',
  onFirstFrameRendered     : 'onFirstFrameRendered',
  onNewAssetRequested      : 'onNewAssetRequested',
  onWaitingForPose         : 'onWaitingForPose',
  onFirstFrameStarted      : 'onFirstFrameStarted',
  onAssetSwitchDone        : 'onAssetSwitchDone',
  onNewTransitionRequested : 'onNewTransitionRequested',
  onNewTransitionRendered  : 'onNewTransitionRendered',
  onFPSCount               : 'onFPSCount'
};

export const DOMDefaultTarget = () => document.querySelector('body');
