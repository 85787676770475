import { VmStore } from '../app/VmCore';
import { DOMDefaultTarget } from '../constants/DOMEvents';
import { AuthenticationOptions, MirrorCallbacks, PrivacyCallbacks } from '../interfaces/InitializationTypes';
import { AppActions } from '../reducers/appReducer';
export * from '../constants/DOMEvents';
export {
  CallbackHelper,
  useBiometricBipa,
  setPrivacyCallbacks,
  setCallbacks
};

let onFaceFoundSet = false;

export const dispatchEvent = (domEvent, details = {}) => {
  DOMDefaultTarget().dispatchEvent(new CustomEvent(domEvent, details));
};

const setPrivacyCallbacks = (
  privacyCallbacks: PrivacyCallbacks
) => {
  if (privacyCallbacks) {
    for (const callback in privacyCallbacks) {
      if (privacyCallbacks[callback]) {
        CallbackHelper[callback] = privacyCallbacks[callback];
      }
    }
  }
};

const setCallbacks = (
  callbacks: MirrorCallbacks
) => {
  CallbackHelper.onAuthUpdated = (authData: AuthenticationOptions) => {
    VmStore.dispatch(AppActions.setAuth(authData));
    callbacks?.onAuthUpdated?.(authData);
  };

  if (callbacks) {
    if (callbacks.onFaceFound) { 
      onFaceFoundSet = true;
      CallbackHelper.onFaceFound = callbacks.onFaceFound;
    }
    if (callbacks.onLowEndDeviceDetected) { CallbackHelper.onLowEndDeviceDetected = callbacks.onLowEndDeviceDetected; }
    if (callbacks.onTransitionAnimation) { CallbackHelper.onTransitionAnimation = callbacks.onTransitionAnimation; }
    if (callbacks.onTransitionAnimationEnd) { CallbackHelper.onTransitionAnimationEnd = callbacks.onTransitionAnimationEnd; }
  }
};

const useBiometricBipa = (): boolean => {
  return onFaceFoundSet;
};

const CallbackHelper: MirrorCallbacks & PrivacyCallbacks = {
  onFaceFound: () => { return; },
  onLowEndDeviceDetected: () => { return; },
  onTransitionAnimation: () => { return; },
  onTransitionAnimationEnd: () => { return; },
  onAuthUpdated: () => { return; },
  onDisplayPage: () => { return; },
  onPrivacyAgree: () => { return; },
  onPrivacyFail: () => { return; },
  onPrivacyReject: () => { return; },
  onPrivacyPolicyClick: undefined,
  onTermsAndConditionsClick: undefined,
  onPrivacyDisplay: () => { return; },
  onMirrorExperience: () => { return; },
};
