const sendXhrRequest = (
  method: string,
  url: string,
  body: any,
  responseType: XMLHttpRequestResponseType,
  responseHeaders: string[] = [],
  options: Record<string, string> = {},
  onProgress?: ((this: XMLHttpRequest, ev: ProgressEvent) => any) | null
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method || "GET", url);
    xhr.responseType = responseType;
    for (const header in options) {
      if (options[header]) {
        xhr.setRequestHeader(header, options[header]);
      }
    }

    xhr.onload = (e: any) => {
      if (xhr.status >= 200 && xhr.status < 300) {

        const responseHeaderValues: {[header: string]: string} = {};
        responseHeaders.forEach((header) => {
          const responseHeaderValue = xhr.getResponseHeader(header);
          if (responseHeaderValue !== null && responseHeaderValue !== undefined) {
            responseHeaderValues[header] = responseHeaderValue;
          }
        });

        resolve({
          status: xhr.status,
          statusText: xhr.statusText,
          response: xhr.response,
          headers: responseHeaderValues
        });
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
          response: xhr.response
        });
      }
    };

    xhr.onerror = (e) => {
      reject({
        status: xhr.status,
        statusText: xhr.statusText,
        response: xhr.response
      });
    };

    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = onProgress;
    }
    xhr.send(body);
  });
};

export {
  sendXhrRequest
};
