import { DeviceDetection } from '@luxottica/vm-devicedetection';
import { SupportedFeatures } from '../interfaces/SupportedFeatures';
import { AnalyticsHelper } from './AnalyticsHelper';

export class SupportedFeaturesHelper {
  public static getSupportedFeatures() {
    return new Promise<SupportedFeatures>((resolve, reject) => {
      const devicedetection = new DeviceDetection();
      return devicedetection.isVideoInputDeviceAvailable().then((available) => {
        if (!available) {
          AnalyticsHelper.onWebcamUnavailable();
        }
        resolve({
          wasmSupport: devicedetection.isWebAssemblySupported(),
          webcamAccess: devicedetection.isUserMediaSupported(),
          webcamAvailable: available,
          webglRendering: devicedetection.isWebGlSupported(),
          isWebView: devicedetection.isWebView(),
        });
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
